const config = {
  v: 2,
  baseURL: 'https://c.hpvirtualbooth.com/api/public/api/',
  baseURLStaging: 'https://c.hpvirtualbooth.com/api-staging/public/api/',
  scrollVideoUrl: 'https://c.hpvirtualbooth.com/api/static-uploads/scrollVideo',
  configEndpoint: 'config',
  cloudfront: {
    baseURL: 'https://dktffdy8n0jtu.cloudfront.net/',
    resized: '_resized',
  },
}

module.exports = config
  